import React from 'react';
import img1 from '../assets/3_.webp';
import logo from '../assets/logo-white.png';
import logo2 from '../assets/Falcon Logo.png';
import logo3 from '../assets/Royal Logo.png';
import { WebsiteText } from '../constants/WebsiteText';
import { Link } from 'react-router-dom';

const TwoSideSection = ({ isArabic }) => {
  return (
    <div dir={isArabic?"rtl":"ltr"} className={`w-full bg-secondary bg-center bg-no-repeat`}>
      <div className="container mx-auto">
        <div className="flex flex-wrap h-full">
          {/* Left Side */}
          <div className="w-full md:w-1/2 px-8 py-14">
            <div className="mb-4">
              <h2 className="text-4xl text-white font-light mb-6">
                {isArabic ? WebsiteText.twoSideSectionHeadingAr : WebsiteText.twoSideSectionHeading}
              </h2>
              <p className="text-white mb-6">
                {isArabic ? WebsiteText.twoSideSectionDescriptionAr : WebsiteText.twoSideSectionDescription}
              </p>

              <div className='flex justify-evenly items-center gap-8 w-full md:w-1/3  my-10'>
                <img src={logo} className=' h-[2em] md:h-[3em] '/>
                <img src={logo2} className=' h-[2em] md:h-[3em] ' />
                <img src={logo3} className=' h-[2em] md:h-[3em] ' />
              </div>
              
              
              <Link to={'/categories'}
                className="inline-block py-3 px-6 bg-white text-primarydark font-bold text-xs uppercase rounded-full hover:bg-redLogo hover:text-white cursor-pointer transition-colors duration-300"
              >
                
                {isArabic ? WebsiteText.browseProductsButtonAr : WebsiteText.browseProductsButton}
              </Link>
            </div>
          </div>

          {/* Right Side */}
          <div className="w-full md:w-1/2 flex justify-end object-contain">
            <img
              src={img1}
              alt="Cover"
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoSideSection;
