import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import MegaMenuCard from "./MegaMenuCard";

const MegaMenu = ({  productsData, isArabic, isMenuOpen }) => {

  return (
    <div 
    className="relative h-full w-full"
    
    >
      
      {isMenuOpen && (
        <div className="h-full w-full bg-white backdrop-blur-10 bg-opacity-40  rounded-lg shadow-lg p-6 z-50">
          <div className="grid grid-cols-3 gap-4">
            {productsData.map((brand) =>
              brand.categories.map((category) => (
                <div key={category.id} className="flex flex-col h-full">
                  <MegaMenuCard category={category} isArabic={isArabic} />
                </div>
              ))
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MegaMenu;
