import React from 'react'

const EgyptSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 32 32">
        <path fill="#fff" d="M1 11H31V21H1z"></path>
        <path d="M5,4H27c2.208,0,4,1.792,4,4v4H1V8c0-2.208,1.792-4,4-4Z" fill="#be2a2c"></path>
        <path d="M5,20H27c2.208,0,4,1.792,4,4v4H1v-4c0-2.208,1.792-4,4-4Z" transform="rotate(180 16 24)"></path>
        <path d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z" opacity=".15"></path>
        <path d="M18.5,18.367h0c-.036-.882-.138-3.253-.176-4.166-.13-1.044-1.131,.042-1.702-.052-.309-.439,.042-1.889-.819-1.503-.199-.039-.39-.096-.44,.074,0,0,0,0,0,0-.023,.079-.019,.178,.015,.15,0,0,0,0,0,0,.146-.12,.308,.051,.332,.197,0,0,0,0,0,0,0,.001,0,.002,0,.003,0-.001,0-.002,0-.003,.062,.286-.15,.856-.342,1.082-.567,.087-1.564-.988-1.692,.052,0,0,0,0,0,0l-.176,4.166h0s0,0,0,0l1.35-1.207-.289,1.025c-.1-.017-.192-.092-.28-.022-.158-.008-.23,.179-.176,.287,.007-.015,.021-.026,.037-.036-.023,.03-.038,.071-.038,.124-.136-.029-.167,.174-.268,.174,.087,.024,.021,.203,.18,.256,.265,.598,3.697,.602,3.965,0,.159-.053,.094-.232,.18-.256-.101,0-.133-.203-.268-.174,0-.053-.014-.093-.037-.124,.017,.009,.03,.02,.037,.036,.054-.108-.018-.295-.176-.287-.088-.07-.181,.005-.281,.022l-.289-1.025s1.349,1.205,1.351,1.207h0Z" fill="#c09300" fill-rule="evenodd"></path>
        <path d="M18.184,18.709c-.008,0-.015,.001-.021,.003,.007,0,.014,0,.021-.003Z" fill="#c09300" fill-rule="evenodd"></path>
        <path d="M13.837,18.712c-.006-.002-.013-.003-.021-.003,.007,.002,.015,.003,.021,.003Z" fill="#c09300" fill-rule="evenodd"></path>
        <path d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z" fill="#fff" opacity=".2"></path>
    </svg>
  )
}

export default EgyptSvg