import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Header from "./components/Header";
import Footer from "./components/Footer";
import About from "./components/Aboutus";
import Contact from "./components/Contact";
import SingleProduct from "./components/SingleProduct";
import './fonts.css'
import Home from './pages/Home';
import TestingComp from './components/TestingComp';
import Contactus from './components/Contactus';
import logo from './assets/Logo.png';
import PasswordPage from './pages/PasswordPage';
import CookieCard from './components/CookieCard';
import CategoryPage from './components/ProductsPage';
import CategoriesList from './components/CategoriesList';
import PrivacyPolicy from './components/PrivacyPolicy';
import ScrollToTop from './components/ScrollToTop';
import staticData from './data.json'
function App() {
  const [isArabic, setIsArabic] = useState(false);
  const [products, setProducts] = useState([]);
  const [contact, setContact] = useState([]);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [accessGranted, setAccessGranted] = useState(true); // New state to control access


  useEffect(() => {
    const fetchUsers = () => {
        setLoading(true);
        setError(null);

        axios.get('https://ramsisindustry.com/api/brands/')
            .then(response => {
                setProducts(response.data);
                return axios.get('https://ramsisindustry.com/api/contact/');
            })
            .then(response2 => {
                setContact(response2.data);
                return axios.get('https://ramsisindustry.com/api/events/');
            })
            .then(response3 => {
                setEvents(response3.data);
                console.log(response3);
            })
            .catch(err => {
              setProducts(staticData.brands);
              setContact(staticData.contact);
              setEvents(staticData.events);
                setError(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    fetchUsers();
}, []);

  if (!accessGranted) {
    return <PasswordPage onAccessGranted={() => setAccessGranted(true)} />;
  }

  if (loading) return <div className='flex justify-center items-center h-screen w-screen'>
    <img className='w-[100px] animate-ping' src={logo} alt="loading" />
  </div>;
  // if (error) return <div>Error: {error.message}، Please try again in a few minutes</div>;
  if (error) {
    
    console.log(error)

  };

  return (
    <Router>
      <ScrollToTop/>
      <div className="bg-white font-Roboto w-[100%] overflow-hidden roboto-black">
        <Navbar productsData={products} isArabic={isArabic} setIsArabic={setIsArabic}/>
        <Routes>
          <Route path="/" element={<Home isArabic={isArabic} events={events} />} />
          <Route path="/about" element={<About isArabic={isArabic}/>} />
          <Route path="/contact" element={<Contactus contact={contact} isArabic={isArabic}/>} />
          <Route path="/categories" element={<CategoriesList products={products} isArabic={isArabic}/>} />
          <Route path="/privacy-policy" element={<PrivacyPolicy isArabic={isArabic}/>} />

          <Route path="/category/:categoryId/:categoryName" element={<CategoryPage productsData={products} isArabic={isArabic} />} />
          <Route path="/test" element={<TestingComp />} />
        </Routes>
        <Footer contact={contact} isArabic={isArabic}/>
        {/* <CookieCard /> */}
      </div>
    </Router>
  );
}

export default App;
