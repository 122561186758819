import React, { useState, useEffect, useRef } from 'react';
import { gsap } from 'gsap';

const ProductCard2 = ({ product, isArabic }) => {
  const [selectedImage, setSelectedImage] = useState(product?.images?.[0]?.image || '');
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [parsedDescription, setParsedDescription] = useState({ type: 'text', content: '' });
  
  const imageRef = useRef(null);
  const containerRef = useRef(null);

  // Get unique sizes and colors from the product variations
  const uniqueSizes = [...new Set(product.variations.map(variation => variation.size).filter(size => size !== null))];
  const uniqueColors = [...new Set(product.variations.map(variation => variation.color))];

  const handleColorChange = (color) => {
    const selectedVariation = product.variations.find(variation => variation.color === color);
    if (selectedVariation && selectedVariation.image) {
      setSelectedImage(selectedVariation.image);
      setIsImageLoaded(false); // Reset loading state when changing the image
    }
  };

  const handleImageLoad = () => {
    setIsImageLoaded(true);
    gsap.fromTo(
      imageRef.current,
      { opacity: 0, scale: 0.9 },
      { opacity: 1, scale: 1, duration: 0.5, ease: 'power2.out' }
    );
  };

  const parseDescription = (description, description_ar, isArabic) => {
    const specs = {};
    
    // Use the correct description based on the isArabic state
    const desc = isArabic ? description_ar : description;
  
    // Check if the selected description is available and is a string
    if (desc && typeof desc === 'string') {
      if (desc.includes(':')) {
        const lines = desc.split('\r\n'); // Splitting based on newline
        lines.forEach((line) => {
          const [key, value] = line.split(':'); // Splitting each line by ':'
          if (key && value) {
            specs[key.trim()] = value.trim();
          }
        });
        return { type: 'key-value', content: specs };
      } else {
        return { type: 'text', content: desc };
      }
    }
  
    return { type: 'text', content: '' }; // Default if no description is available
  };

  // Use effect to recalculate the description when isArabic changes
  useEffect(() => {
    const parsedDesc = parseDescription(product.description, product.description_ar, isArabic);
    setParsedDescription(parsedDesc);
  }, [isArabic, product.description, product.description_ar]);

  const handleImageChange = (image) => {
    setSelectedImage(image);
    setIsImageLoaded(false);
  };

  useEffect(() => {
    // Animate the entire card when it first mounts
    gsap.fromTo(containerRef.current, { opacity: 0, y: 20 }, { opacity: 1, y: 0, duration: 0.8, ease: 'power2.out' });
    
    if (selectedImage) {
      const img = new Image();
      img.src = selectedImage;
      img.onload = handleImageLoad;
    }
  }, [selectedImage]);

  return (
    <div ref={containerRef} className={`relative text-primarydark bg-white shadow-md rounded-lg p-6 mb-6 ${isArabic ? 'text-right' : 'text-left'}`}>
      
      <h3 className="text-2xl font-bold my-8">{isArabic ? product.name_ar : product.name}</h3>
      <p className='my-8'>{isArabic ? product.short_description_ar : product.short_description}</p>

      {/* Responsive flex layout */}
      <div className={`flex flex-col md:flex-row ${isArabic ? 'md:flex-row-reverse' : ''} items-center md:items-start`}>
        
        {/* Thumbnail Image Section */}
        <div className="flex md:flex-col items-center gap-4 mr-4 my-auto">
          {product.images.map((image, index) => (
            <div
              key={index}
              className={`w-16 h-16 border-2 border-primarydark rounded-full overflow-hidden cursor-pointer ${selectedImage === image.image ? 'ring-2 ring-secondary' : ''}`}
              onClick={() => handleImageChange(image.image)}
            >
              <img src={image.image} alt={`Thumbnail ${index + 1}`} className="w-full h-full object-contain" />
            </div>
          ))}
        </div>

        {/* Main Product Image Section */}
        <div className="relative w-72 h-72 md:w-96 md:h-96 mb-6 md:mb-0">
          {!isImageLoaded && (
            <div className="bg-gray-300 animate-pulse rounded-full w-full h-full"></div>
          )}
          <img
            ref={imageRef}
            src={selectedImage}
            alt={isArabic ? product.name_ar : product.name}
            className={`rounded-xl w-full h-full object-contain  ${isImageLoaded ? '' : 'hidden'}`}
          />
        </div>

        {/* Product Details Section */}
        <div className="w-full md:w-1/2 md:px-6 ">
          {/* Specifications or Plain Text */}
          {parsedDescription.type === 'key-value' ? (
            <div className="mb-2">
              <h4 className="text-lg font-semibold mb-2 text-primarydark">{isArabic ? 'المواصفات' : 'Specifications'}:</h4>
              <div className="border-y border-primarydark  p-2">
                <ul className="space-y-4 divide-y divide-primarydark">
                  {Object.entries(parsedDescription.content).map(([key, value], index) => (
                    <li key={index} className={`py-1 ${isArabic ? 'flex flex-row-reverse text-end' : 'flex'} gap-4`}>
                      <span className={`block  text-primary font-semibold min-w-[150px] ${isArabic?'text-end':'text-start'} whitespace-nowrap`}>{key}{isArabic ? "" : ":"}</span>
                      <span className="block text-primarydark">{value.trim()}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>


          ) : (
            <div className="mb-4 p-4">
              <p className="text-primarydark">{parsedDescription.content}</p>
            </div>
          )}

          {/* Size Variations */}
          {uniqueSizes.length > 0 && (
            <div className="mb-4">
              <h4 className="text-lg font-semibold mb-2">{isArabic ? 'الحجم' : 'Size'}:</h4>
              <div className="flex">
                {uniqueSizes.map((size, index) => size!==null && (
                  <span
                    key={index}
                    className="inline-block px-3 py-1 mr-2 border rounded cursor-pointer"
                    onMouseEnter={(e) => gsap.to(e.target, { scale: 1.2, duration: 0.3 })}
                    onMouseLeave={(e) => gsap.to(e.target, { scale: 1, duration: 0.3 })}
                  >
                    {size.toUpperCase()}
                  </span>
                ))}
              </div>
            </div>
          )}

          {/* Color Variations */}
          {uniqueColors.length > 0 && (
            <div className="mb-4">
              <h4 className="text-lg font-semibold mb-2">{isArabic ? 'اللون' : 'Color'}:</h4>
              <div className="flex">
                {uniqueColors.map((color, index) => (
                  <span
                    key={index}
                    style={{ backgroundColor: color }}
                    className="inline-block w-6 h-6 mr-2 mt-2 rounded-full border cursor-pointer"
                    onClick={() => handleColorChange(color)}
                    onMouseEnter={(e) => gsap.to(e.target, { scale: 1.3, duration: 0.3 })}
                    onMouseLeave={(e) => gsap.to(e.target, { scale: 1, duration: 0.3 })}
                  ></span>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductCard2;
