import React, { useState } from 'react';
import axios from 'axios';

const TestingComp = () => {
  const [file, setFile] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFileSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('image', file);

    try {
      const response = await axios.post('https://flokisoft.com/shippingspot/apiupload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('File uploaded successfully', response.data);
    } catch (error) {
      console.error('Error uploading file', error);
    }
  };

  const handleRegistrationSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('https://flokisoft.com/shippingspot/apicall', {
        email,
        password,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('Registration successful', response.data);
    } catch (error) {
      console.error('Error during registration', error);
    }
  };

  return (
    <div className='m-96'>
      <form onSubmit={handleFileSubmit}>
        <input type="file" onChange={handleFileChange} />
        <button type="submit">Upload Photo</button>
      </form>
      <form onSubmit={handleRegistrationSubmit}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Register</button>
      </form>
    </div>
  );
};

export default TestingComp;