export const NavLinks = [
  {
    id: "home",
    title: "Home",
    titleAr: "الرئيسية",
    url:"/"
  },
  {
    id: "about",
    title: "About us",
    titleAr: "من نحن",
    url:"/about"
  },
  
  {
    id: "categories",
    title: "Products",
    titleAr: "المنتجات",
    url: "/categories",
    
  },
  {
    id: "contact",
    title: "Contact us",
    titleAr: "اتصل بنا",
    url: "/contact",
    
  },
];
