import React, { useState, useEffect } from "react";
import { NavLinks } from "../constants/NavConsts";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/Logo.png";
import EgyptSvg from "./EgyptSvg";
import UkSvg from "./UkSvg";
import gsap from "gsap";
import MegaMenu from "./MegaMenu";
import { FaAngleDown, FaAngleUp, FaArrowDown, FaArrowUp } from "react-icons/fa";

const Navbar = ({ productsData, isArabic, setIsArabic }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [selectedNavItem, setSelectedNavItem] = useState(null);
  const [isCategoriesOpen, setIsCategoriesOpen] = useState(false); // State to control dropdown visibility

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  
  const location = useLocation(); // Get the current location

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isSticky) {
      gsap.to(".navbar", { backgroundColor: "white", duration: 0.3 });
    } else {
      gsap.to(".navbar", { backgroundColor: "transparent", duration: 0.3 });
    }
  }, [isSticky]);

  useEffect(() => {
    const currentLink = NavLinks.find((link) => link.url === location.pathname);
    if (currentLink) {
      setSelectedNavItem(currentLink.id);
    }
  }, [location.pathname]); // Update when the pathname changes

  return (
      <header className={`w-full z-[99999] navbar ${isSticky ? "fixed top-0 bg-white shadow-md" : "absolute inset-x-0 top-0"}`}>
      <nav
        className={`relative w-full flex items-center justify-between p-6 lg:px-8 transition-all duration-500 ${
          isArabic && "flex-row"
        } `}
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <Link to="/" className="-m-1.5 p-1.5 z[9999]">
            <span className="sr-only">Ramsis</span>
            <img className="h-12 w-auto" src={logo} alt="Logo" />
          </Link>
        </div>
        <div className="flex lg:hidden z[99999]">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-primarydark z[99999]"
            onClick={toggleMenu}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </button>
        </div>
        <div
          className={`hidden lg:flex ${
            isArabic && "flex-row-reverse"
          } lg:gap-x-12`}
        >
          {NavLinks?.map((link) => (
            <div
            className="relative"
            onMouseEnter={link.id==='categories'?()=>setIsMenuOpen(true):()=>console.log('')}
            onMouseLeave={()=>setIsMenuOpen(false)}
            >
            <Link
              key={link.id}
              to={link.url}
              className={`text-sm font-semibold leading-6 transition-all duration-500 text-primarydark z[60] border-b-2 pb-1 hover:border-redLogo ${
                selectedNavItem === link.id ? "text-redLogo border-redLogo" : "border-transparent"
              }`}
              onClick={() => setSelectedNavItem(link.id)}
            >
              {isArabic ? link.titleAr : link.title}
              
            </Link>
            {link.id==='categories' && isMenuOpen&&
            
            <div className="w-[50vw] max-h-[60vh] absolute top-[100%] right-[-25vw] hidden md:block">
              <MegaMenu isMenuOpen={isMenuOpen} productsData={productsData} isArabic={isArabic} />
            </div>
            }
            </div>
          ))}

          
        </div>
        
        <div className="hidden lg:gap-4 lg:items-center lg:w-full lg:flex lg:flex-1 lg:justify-end">
          <div
            className="rounded-full self-center"
            onClick={() => setIsArabic((prev) => !prev)}
          >
            {isArabic ? <UkSvg /> : <EgyptSvg />}
          </div>
        </div>
          
      </nav>
      {/* Mobile menu, show/hide based on menu open state. */}
      {menuOpen && (
        <div className="lg:hidden" role="dialog" aria-modal="true">
          {/* Background backdrop */}
          <div className="fixed inset-0 z-50"></div>
          <div className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <Link to="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Ramsis</span>
                <img className="h-8 w-auto" src={logo} alt="Logo" />
              </Link>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-primarydark"
                onClick={toggleMenu}
              >
                <span className="sr-only">Close menu</span>
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div
                className={`-my-6 divide-y divide-gray-500/10 flex flex-col ${
                  isArabic ? "items-end" : "items-start"
                }`}
              >
                <div className="space-y-2 py-6">
                  {NavLinks?.map((link) => (
                    <div key={link.id} className="relative">
                      {link.title === 'Products' ? (
                        // Render an accordion for Categories
                        <div className="w-full">
                          <button
                            className={`flex ${isArabic?"flex-row-reverse text-end":" text-start"} items-center justify-between w-full rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 ${
                              selectedNavItem === link.id ? 'text-secondary' : ''
                            }`}
                            onClick={() => {
                              setSelectedNavItem(link.id);
                              setIsCategoriesOpen(!isCategoriesOpen);
                            }}
                          >
                            <span>{isArabic ? 'المتجات' : 'Products'}</span>
                            {isCategoriesOpen ? (
                              <FaAngleUp className="mx-auto" />
                            ) : (
                              <FaAngleDown className="mx-auto" />
                            )}
                          </button>

                          {/* Accordion content for categories */}
                          <div
                            className={`transition-all duration-300 ease-in-out overflow-hidden ${
                              isCategoriesOpen ? 'max-h-screen' : 'max-h-0'
                            }`}
                          >
                            <div className={`mx-6 mt-2 space-y-2 ${isArabic?"text-end":"text-start"}`}>
                              {productsData[0].categories?.map((category) => (
                                <Link
                                  key={category.id}
                                  to={`/category/${category.id}/${category.name.replace(/\s+/g, '-').toLowerCase()}`} 
                                  className="block px-4 py-2 text-base text-gray-700 hover:bg-gray-50"
                                  onClick={() => {
                                    setSelectedNavItem(link.id);
                                    setIsCategoriesOpen(false); // Optionally close accordion on category click
                                    setMenuOpen(false)
                                  }}
                                >
                                  {isArabic ? category.name_ar : category.name}
                                </Link>
                              ))}
                            </div>
                          </div>
                        </div>
                      ) : (
                        // Render regular links for other items
                        <Link
                          to={link.url}
                          className={`block ${isArabic?"text-end":"text-start"} rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 ${
                            selectedNavItem === link.id ? 'text-secondary' : ''
                          }`}
                          onClick={() => {
                            setSelectedNavItem(link.id);
                            setIsCategoriesOpen(false); // Close accordion if another link is clicked
                            setMenuOpen(false)
                          }}
                        >
                          {isArabic ? link.titleAr : link.title}
                        </Link>
                      )}
                    </div>
                  ))}
                </div>
  
                <div className="py-6">
                  <div
                    className="rounded-full self-center"
                    onClick={() => setIsArabic((prev) => !prev)}
                  >
                    {isArabic ? <UkSvg /> : <EgyptSvg />}
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      )}
      
    </header>
  );
};

export default Navbar;
