import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { WebsiteText } from "../constants/WebsiteText";
import BackgroundSlider from "./BackgroundSlider";
import folderp1 from "../assets/ramsis-bg-1.jpg";
import folderp2 from "../assets/falcon-desk-1.jpg";
import folderp3 from "../assets/(4).webp";
import ScrollTrigger from 'gsap/ScrollTrigger';
import { Link } from "react-router-dom";

gsap.registerPlugin(ScrollTrigger);

const Header = ({ isArabic }) => {
  const [sliderSlide, setSliderSlide] = useState(1)
  const gradientTopRef = useRef(null);
  const gradientBottomRef = useRef(null);
  const textRef = useRef(null);
  const buttonRef = useRef(null);

  const headerRef = useRef(null);

  useEffect(() => {
    const ctx = gsap.context(() => {
      const animationConfig1 = {
        scale: 0.5,
        x: -200,
        y: 200,
        opacity: 0,
        duration: 1,
        ease: 'power2.out',
      };

      const createTimeline = (trigger, elements, animationConfig) => {
        gsap.timeline({
          scrollTrigger: {
            trigger: trigger,
            start: 'top top',
            end: 'bottom top',
            markers: false,
            scrub: true,
          },
        }).fromTo(elements, { x: 0, y: 0, scale: 1, opacity: 1 }, animationConfig);
      };

      createTimeline(headerRef.current, '.slideleft', animationConfig1);
    }, headerRef);

    return () => ctx.revert();
  }, []);

  useEffect(() => {
    gsap.fromTo(
      gradientTopRef.current,
      { opacity: 0, y: -100 },
      {
        opacity: 0.3,
        y: 0,
        duration: 1.5,
        ease: "power3.out",
      }
    );

    gsap.fromTo(
      gradientBottomRef.current,
      { opacity: 0, y: 100 },
      {
        opacity: 0.3,
        y: 0,
        duration: 1.5,
        ease: "power3.out",
      }
    );

    gsap.fromTo(
      textRef.current,
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        y: 0,
        duration: 1.5,
        ease: "power3.out",
        delay: 0.5,
      }
    );

    gsap.fromTo(
      buttonRef.current.children,
      { opacity: 0, y: 30 },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        ease: "power3.out",
        delay: 1,
        stagger: 0.2,
      }
    );
  }, []);

  return (
    <div ref={headerRef} className="relative flex flex-col items-center justify-center max-h-screen overflow-hidden">
      <div className="absolute inset-0 w-full h-full max-h-screen">
        <BackgroundSlider setSliderSlide={setSliderSlide} images={[folderp1, folderp2, ]} />
      </div>

      <div ref={gradientTopRef} className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80">
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        ></div>
      </div>

      <div className="slideleft mx-auto max-w-2xl py-32 sm:py-48 lg:py-56 z-[50]">
        <div ref={textRef} className="text-center px-4">
          <h1 className="text-4xl font-bold tracking-tight uppercase text-gray-200 sm:text-5xl z-[50]">
            {isArabic ? WebsiteText.heroHeadAr : WebsiteText.heroHead}
          </h1>
          <p className="mt-6 text-lg leading-8 text-gray-100 transition-all duration-300">
            {isArabic ? sliderSlide===1?WebsiteText.heroText2AR:WebsiteText.heroText2AR : sliderSlide===5?WebsiteText.heroText2:WebsiteText.heroText2}
          </p>
          <div ref={buttonRef} className="mt-10 flex items-center justify-center gap-x-6 z-[50]">
            <Link to="/categories" className={`comanimate1 text-white text-center px-4 py-3 my-8 text-base uppercase lg:w-1/3 rounded-full ${isArabic?'':'tracking-widest'} bg-primarydark hover:bg-redLogo transition-colors duration-500  cursor-pointer`}>
              {isArabic ? WebsiteText.aboutMainButtonAR : WebsiteText.aboutMainButton}
            </Link>
            <Link to="/about" className={`text-sm font-semibold ${isArabic?'leading-0':'leading-6'} text-gray-200 z-[50]`}>
              {isArabic ? WebsiteText.button2Ar : WebsiteText.button2} <span aria-hidden="true">→</span>
            </Link>
          </div>
        </div>
      </div>

      <div ref={gradientBottomRef} className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
        <div
          className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        ></div>
      </div>
    </div>
  );
};

export default Header;
