import React from 'react';
import { Link } from 'react-router-dom';

const MegaMenuCard = ({ category, isArabic }) => {
  return (
    <div className="w-11/12 h-full max-w-2xl mx-auto bg-white shadow-2xl rounded-2xl  transition-all duration-300 " style={{
      backgroundImage: `url(${category.image?category.image:''})`,
      backgroundSize: '120%',
      backgroundPosition: 'center',
    }}>
      <div className="flex flex-col md:flex-row items-center p-2 h-full bg-primary bg-opacity-70 rounded-2xl">
        
        <div className="md:w-3/5 md:pl-6 mt-8 md:mt-0 text-center md:text-left">
          <h3 className="text-lg font-bold text-white mb-4">{isArabic?category.name_ar:category.name}</h3>
          <Link
            to={`/category/${category.id}/${category.name.replace(/\s+/g, '-').toLowerCase()}`} 
            
            className="inline-flex cursor-pointer justify-center items-center bg-gradient-to-r from-primary to-secondary text-white py-2 px-4 rounded-full shadow-lg transition-transform duration-300 hover:scale-105"
          >
            View
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MegaMenuCard;
