import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { gsap } from 'gsap';

const CategoriesList = ({ products, isArabic }) => {
  const categoryRefs = useRef([]);

  useEffect(() => {
    categoryRefs.current.forEach((el) => {
      const overlay = el.querySelector('.category-overlay');
      
      // Check if the device is larger than small devices (640px)
      if (window.innerWidth > 640) {
        gsap.set(overlay, { y: '100%' }); // Initially hide the overlay by sliding it down

        el.addEventListener('mouseenter', () => {
          gsap.to(overlay, { y: '0%', duration: 0.5, ease: 'power2.out' });
        });

        el.addEventListener('mouseleave', () => {
          gsap.to(overlay, { y: '100%', duration: 0.5, ease: 'power2.out' });
        });
      } else {
        // On small devices, keep the overlay visible
        gsap.set(overlay, { y: '0%' });
      }
    });
  }, []);

  return (
    <div>
      <div className="mx-auto max-w-2xl px-4 py-40 sm:px-6 sm:py-40 lg:max-w-7xl lg:px-8">
        <h2 className="sr-only">{isArabic ? 'الفئات' : 'Categories'}</h2>

        <div className="grid grid-cols-1 gap-x-6 gap-y-10 md:grid-cols-2 xl:gap-x-8">
          {products?.map((product) =>
            product.categories?.map((category, index) => (
              <Link
              to={`/category/${category.id}/${category.name.replace(/\s+/g, '-').toLowerCase()}`}  // Add the category name in the URL
              key={category.id}
              ref={(el) => (categoryRefs.current[index] = el)}
              className="group relative flex flex-col justify-between items-center rounded-xl overflow-hidden shadow-xl bg-transparent"
            >

                <div className="relative w-full flex justify-center rounded-lg overflow-hidden">
                  <img
                    src={category.image || 'https://via.placeholder.com/150'}
                    alt={isArabic ? category.description_ar : category.description}
                    className="object-cover max-h-80"
                  />

                  <div className="category-overlay absolute inset-0 bg-primary bg-opacity-70 text-white flex items-center justify-center text-lg font-bold">
                    {isArabic ? category.name_ar : category.name}
                  </div>
                </div>
              </Link>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default CategoriesList;
