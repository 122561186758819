import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo-white.png";
import { WebsiteText } from "../constants/WebsiteText";
import { IoLogoWhatsapp } from "react-icons/io5";
import { MdOutlineLocalPhone } from "react-icons/md";
import { MdOutlineMail } from "react-icons/md";

const Footer = ({ isArabic, contact }) => {
  return (
    <footer className="bg-secondary text-white">
      <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8 w-full">
        <div className={`flex flex-wrap justify-between space-y-10 lg:space-y-0 w-full ${isArabic?'flex-row-reverse text-end justify-end':'flex-row items-start '}`}>
          <div className={`w-full lg:w-1/3 flex flex-col ${isArabic?'items-end':'items-start'}`}>
            <img src={logo} className="mr-5 h-16" alt="logo" />
            <p className="mt-4 text-sm text-white">
              {isArabic ? WebsiteText.footerTextAR : WebsiteText.footerText}
            </p>
          </div>
          <div className="flex flex-row flex-wrap justify-between w-1/2">
            <div className="w-full my-2 lg:w-1/2">
              <p className="font-medium">{isArabic ? "التنقل" : "Navigate"}</p>
              <nav className="flex flex-col mt-4 space-y-2 text-sm text-white">
                <Link className="hover:opacity-75 z-50" to="/">
                  {isArabic ? "الرئيسية" : "Home"}
                </Link>
                <Link className="hover:opacity-75 z-50" to="/about">
                  {isArabic ? "من نحن" : "About us"}
                </Link>
                <Link className="hover:opacity-75 z-50" to="/categories">
                  {isArabic ? "المنتجات" : "Categories"}
                </Link>
                <Link className="hover:opacity-75 z-50" to="/privacy-policy">
                  {isArabic ? "سياسة الخصوصية" : "Privacy Policy"}
                </Link>
              </nav>
            </div>

            <div className="w-full my-2 lg:w-1/2">
              <p className="font-medium">
                {isArabic ? "اتصل بنا" : "Contact Us"}
              </p>
              <nav className={`flex flex-col mt-4 space-y-2 text-sm text-white ${isArabic ? 'text-end items-end' : ''}`}>
  {/* Non-International Numbers */}
  {contact.number.length > 0 &&
    contact.number
      .filter((num) => !num.is_international) // Filter non-international numbers
      .map((num) => (
        <a
          key={num.phone_number}
          className={`hover:opacity-75 flex ${isArabic && 'flex-row-reverse'} gap-2 items-center cursor-pointer z-10`}
          href={num.has_whatsapp ? `https://wa.me/${num.phone_number}` : `tel:${num.phone_number}`}
        >
          {num.has_whatsapp ? (
            <IoLogoWhatsapp />
          ) : (
            <MdOutlineLocalPhone />
          )}
          {num.phone_number}
        </a>
      ))}

  {/* Email */}
  <a
    className={`hover:opacity-75 flex ${isArabic && 'flex-row-reverse'} gap-2 items-center cursor-pointer z-10`}
    href={`mailto:${contact.email}`}
  >
    <MdOutlineMail />
    {contact.email}
  </a>

  {/* International Inquiries Section */}
  {contact.number.some((num) => num.is_international) && (
    <div className="flex flex-col gap-4">
      <div className="mt-4">{isArabic ? 'خارج مصر' : 'For international inquiries'}</div>
      {contact.number
        .filter((num) => num.is_international) // Filter international numbers
        .map((num) => (
          <a
            key={num.phone_number}
            className={`hover:opacity-75 flex ${isArabic && 'flex-row-reverse'} gap-2 items-center cursor-pointer z-10`}
            href={num.has_whatsapp ? `https://wa.me/${num.phone_number}` : `tel:${num.phone_number}`}
          >
            {num.has_whatsapp ? (
              <IoLogoWhatsapp />
            ) : (
              <MdOutlineLocalPhone />
            )}
            {num.phone_number}
          </a>
        ))}
    </div>
  )}
</nav>

            </div>
          </div>
        </div>
        <div className="flex mt-8 space-x-6 text-white w-full justify-center">
          <a
            className="hover:opacity-75"
            href="https://www.facebook.com/ramsisindustry/"
            target="_blank"
            rel="noreferrer"
          >
            <span className="sr-only"> Facebook </span>
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                clipRule="evenodd"
              />
            </svg>
          </a>
          <a
            className="hover:opacity-75"
            href="https://www.linkedin.com/company/ramsis-industry"
            target="_blank"
            rel="noreferrer"
          >
            <span className="sr-only"> Linkedin </span>
            <svg
              className="w-6 h-6"
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 448 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"></path>
            </svg>
          </a>
        </div>
        <p className="mt-8 text-xs text-white text-center">
          &copy; 2024 Ramsis Industry. Powered by <a target="_blank" rel="noopener noreferrer" href="https://flokisoft.com/" className="text-redLogo">FlokiSoft</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
