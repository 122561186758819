import React, { useState, useEffect, useRef } from 'react';
import { gsap } from 'gsap';

const BackgroundSlider = ({ images, setSliderSlide }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const countRef = useRef(0); // Use useRef to store count across renders
  const slidesRef = useRef([]);
  const intervalRef = useRef(null); // Use useRef to store interval ID

  useEffect(() => {
    // Function to transition to the next slide
    const nextSlide = () => {
      const nextIndex = (currentIndex + 1) % images.length;
      const currentSlide = slidesRef.current[currentIndex];
      const nextSlide = slidesRef.current[nextIndex];
      console.log(currentIndex);
      

      // Prepare the next slide to enter from the right
      gsap.set(nextSlide, { xPercent: 100 });

      // Animate the transition
      gsap.timeline()
        .to(currentSlide, {
          xPercent: -100, // Slide out current slide to the left
          duration: 1,
          ease: 'power2.inOut',
        })
        .to(nextSlide, {
          xPercent: 0, // Slide in next slide from the right
          duration: 1,
          ease: 'power2.inOut',
        }, 0);

      setCurrentIndex(nextIndex); // Update currentIndex to the next slide
      setSliderSlide(nextIndex)
    };

    if (countRef.current === 0) {
      nextSlide(); // Run the transition immediately on mount
      countRef.current = 1;
    } else {
      // Auto-transition slides every 3 seconds
      intervalRef.current = setInterval(nextSlide, 3000);
    }

    return () => clearInterval(intervalRef.current); // Cleanup on component unmount
  }, [currentIndex, images.length]); // Depend on currentIndex and images.length

  return (
    <div className="w-full h-screen overflow-hidden relative">
      <div className="relative flex w-full h-full ">
        <div className='w-full h-full absolute inset-0 bg-gradient-to-b from-white via-primarydark to-black opacity-55 z-10'></div>
        {images?.map((src, index) => (
          <div
            key={index}
            className="absolute inset-0 w-full h-full flex items-center justify-center"
            ref={(el) => (slidesRef.current[index] = el)}
          >
            <img
              src={src}
              alt={`Slide ${index + 1}`}
              className="w-full h-full object-cover -z-10"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BackgroundSlider;
