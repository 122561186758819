import React from "react";

const PrivacyPolicy = ({ isArabic }) => {
  return (
    <div className={`bg-gray-50 py-20 px-6 lg:px-24 ${isArabic ? 'text-right' : 'text-left'}`}>
      <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-8">
        <h1 className="text-3xl font-bold text-gray-800 mb-6">
          {isArabic ? "سياسة الخصوصية" : "Privacy Policy"}
        </h1>

        <p className="text-gray-700 mb-4">
          {isArabic
            ? "في رامسيس، خصوصيتك مهمة جدًا بالنسبة لنا. توضح سياسة الخصوصية هذه كيفية جمعنا واستخدامنا وحمايتنا لمعلوماتك الشخصية. نحن ملتزمون بضمان خصوصية زوارنا ومستخدمينا."
            : "At Ramsis, your privacy is very important to us. This privacy policy explains how we collect, use, and protect your personal information. We are committed to ensuring the privacy of our visitors and users."
          }
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          {isArabic ? "المعلومات التي نجمعها" : "Information We Collect"}
        </h2>
        <p className="text-gray-700 mb-4">
          {isArabic
            ? "لا تجمع رامسيس معلومات شخصية تتجاوز الاسم وعنوان البريد الإلكتروني الذي تزودنا به من خلال صفحة 'اتصل بنا'. يتم جمع هذه المعلومات فقط بغرض الرد على استفساراتك وتقديم الدعم."
            : "Ramsis does not collect personal information beyond the name and email address you provide through our Contact Us page. This information is collected solely for the purpose of responding to your inquiries and providing customer support."
          }
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          {isArabic ? "كيفية استخدامنا لمعلوماتك" : "How We Use Your Information"}
        </h2>
        <p className="text-gray-700 mb-4">
          {isArabic ? "نستخدم المعلومات التي نجمعها في:" : "The information we collect is used to:"}
        </p>
        <ul className="list-disc list-inside text-gray-700 mb-4">
          <li>{isArabic ? "الرد على استفساراتك ورسائلك." : "Respond to your queries and messages."}</li>
          <li>{isArabic ? "تقديم الدعم لأي مشكلات قد تواجهها." : "Provide support for any issues you raise."}</li>
          <li>{isArabic ? "تحسين خدماتنا بناءً على ملاحظاتك." : "Improve our services based on your feedback."}</li>
        </ul>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          {isArabic ? "حماية البيانات" : "Data Protection"}
        </h2>
        <p className="text-gray-700 mb-4">
          {isArabic
            ? "نحن ملتزمون بحماية المعلومات الشخصية التي تشاركها معنا. لا تبيع أو تتاجر أو تؤجر رامسيس معلوماتك الشخصية للآخرين. نحن نستخدم تدابير أمان مناسبة لضمان أن بياناتك آمنة من الوصول أو الكشف غير المصرح به."
            : "We are committed to protecting the personal information you share with us. Ramsis does not sell, trade, or rent your personal information to others. We use appropriate security measures to ensure your data is safe from unauthorized access or disclosure."
          }
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          {isArabic ? "ملفات تعريف الارتباط" : "Cookies"}
        </h2>
        <p className="text-gray-700 mb-4">
          {isArabic
            ? "لا تستخدم رامسيس ملفات تعريف الارتباط لتتبع الزوار أو جمع معلومات شخصية إضافية. لا ننخرط في ممارسات الإعلانات أو تبادل البيانات."
            : "Ramsis does not use cookies to track visitors or collect additional personal information. We do not engage in advertising or data-sharing practices."
          }
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          {isArabic ? "الروابط الخارجية" : "Third-Party Links"}
        </h2>
        <p className="text-gray-700 mb-4">
          {isArabic
            ? "قد يحتوي موقعنا على روابط لمواقع أخرى. يرجى ملاحظة أننا غير مسؤولين عن ممارسات الخصوصية لتلك المواقع. نحثك على مراجعة سياسات الخصوصية الخاصة بتلك المواقع قبل مشاركة معلوماتك معها."
            : "Our website may contain links to other websites. Please note that we are not responsible for the privacy practices of those websites. We encourage you to review the privacy policies of those sites before sharing your information with them."
          }
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          {isArabic ? "التغييرات على هذه السياسة" : "Changes to This Policy"}
        </h2>
        <p className="text-gray-700 mb-4">
          {isArabic
            ? "تحتفظ رامسيس بالحق في تحديث أو تغيير سياسة الخصوصية هذه في أي وقت. سيتم نشر أي تغييرات على هذه الصفحة، لذلك نشجعك على مراجعتها دوريًا."
            : "Ramsis reserves the right to update or change this privacy policy at any time. Any changes will be posted on this page, so we encourage you to review it periodically."
          }
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          {isArabic ? "اتصل بنا" : "Contact Us"}
        </h2>
        <p className="text-gray-700 mb-4">
          {isArabic
            ? "إذا كانت لديك أي أسئلة أو مخاوف بشأن سياسة الخصوصية هذه أو معلوماتك الشخصية، فلا تتردد في الاتصال بنا عبر صفحة "
            : "If you have any questions or concerns about this privacy policy or your personal information, please feel free to contact us via the "}
          <a href="/contact" className="text-blue-500 hover:underline">
            {isArabic ? "اتصل بنا" : "Contact Us"}
          </a>{" "}
          {isArabic ? "." : "page."}
        </p>

        <p className="text-sm text-gray-500">
          {isArabic ? "آخر تحديث: سبتمبر 2024" : "Last updated: September 2024"}
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
